.info-modal-container {
    display: flex;
    flex-direction: column;
    height: 90%;
    overflow-y: scroll;
}

.clickable-icon:hover {
    cursor: pointer;
}

.clickable-icon {
    height: 24px;
}