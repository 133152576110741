.add-subtitle-container {
    display: flex;
    flex-direction: column;
}

.modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px 8px 0px;
}

.modal-header-heading {
    margin: 0px 0px 8px 0px;
}

.modal-change-alignment-container {
    display: "flex";
}

.modal-alignment-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.modal-alignment-button-container {
    margin-left: 6px;
}

.list-item-container {
    list-style: none;
    border-radius: 5px;
    margin-bottom: 10px;
}

.list-item-wrapper {
    background-color: rgb(201, 201, 201);
}

/*Toolbar Styling*/

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px;
}

.checkbox-container {
    display: flex;
    align-items: center;
}

.checkbox-text {
    padding-bottom: 2px;
}

.checkbox {
    padding-top: 0px;
    margin-top: 0px;
}

.clickable-icon:hover {
    cursor: pointer;
}

/*list title container styling*/
.list-title-container {
    display: flex;
    align-items: center;
    padding: 0px 10px 8px 10px;
}

.title-input-container {
    display: flex;
    flex: 4;
    padding: 0px 8px 0px 8px;
}

.title-input {
    width: 100%;
}

.time-input-container {
    display: flex;
    flex: 1;
}

.alignment-title {
    padding: 0px 10px 0px 10px;
    font-weight: bold;
    margin-bottom: 0px;
}

/*edit the alignment buttons and inputs styling*/
.change-alignment-container {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 4px 0px;
    padding: 0px 10px 0px 10px;
}

.alignment-container {
    display: flex;
    align-items: center;
}

.alignment-button-container {
    margin: 0px 4px 0px 4px;
}

/*add button styling*/
.add-subtitle-button-container {
    display: flex;
    justify-content: space-around;
    height: 16px;
    width: 100%;
}

.clickable-icon {
    height: 24px;
}

.list-item-action-button {
    transition: opacity 0.2s;
}