.scroll-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.video-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
}

.toolbar-container {
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 0px;
}

.drag-drop-container {
  max-width: 300px !important;
}

.file-handling-container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.video-player-container {
  position: relative;
}

.MuiAutocomplete-clearIndicator {
  fill: "white";
}

.alert-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.response-alert-container {
  transition: opacity 0.5s;
  margin: 0px 8px 0px 0px;
  display: flex;
  height: 100%;
  justify-content: center;
}

#free-solo-demo {
  font-size: 14px;
}

.toolbar-container {
  padding: 8px 8px 0px 8px;
}

.subtitle-list-container {
  padding: 5px 5px 0px 0px;
  height: 100%;
}

.subtitle-list-container-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.list-render-container {
  height: 100px;
}

.player-config-row {
  display: flex;
  flex-direction: column;
  height: 30vh;
  background-color: #f0f0f0;
}

.player-config {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px 0px 8px;
}

.list-edit-config-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selected-traverse-button {
  margin: 0px 8px 0px 0px;
}

.selected-traverse-button-disabled {
  pointer-events: none !important;
  opacity: 50px !important;
}

.clickable-icon:hover {
  cursor: pointer;
}

.clickable-icon {
  height: 24px;
}

.autoscroll-switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.autoscroll-switch-text {
  margin: 0px;
  font-size: 14px;
}

/*loading styling*/
.list-loading-spinner-container {
  position: absolute;
  z-index: 999999;
}

.virtual-list-container {
  height: 0px !important;
}

.export-button {
  margin: 0px 4px 0px 0px !important;
}

.edit-all-button {
  margin: 0px 8px 0px 0px !important;
}

.info-modal-button {
  margin: 0px 8px 0px 0px;
}

@media only screen and (max-width: 1000px) {
  .export-button {
    height: 38px !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
}
