.video-container .video-js-subtitle {
    display: flex!important; 
    top:0!important; 
    position: absolute!important;
    overflow: hidden;
}

.video-js-subtitle {
    pointer-events: none;
    z-index: 10!important;
    height: 100%;
    width: 100%;
}

#subtitle {
    transition: opacity 0.1s;
}