@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

.action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 16px 4px 16px 4px;
    margin: 0px 0px 4px 0px!important;
    border-radius: 2.5px;
    background-color: #FFFFFF;
    border: 1px solid #14213D;
    overflow: hidden;
}

.action-content {
    padding: 0px 2px 0px 2px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif; /* Add this line */
}

.action-container:hover {
    cursor: grab;
}