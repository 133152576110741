.text-submit-container {
    display: flex;
    align-items: center;
    margin: 4px 4px 4px 0px;
}

.submit-button-container {
    margin: 0px 4px 0px 8px;
}

@media only screen and (max-width: 1000px) {
    .insert-button {
        height: 38px!important;
        font-size: 12px!important;
        line-height: 14px!important;
    }
}