.list-item-container {
  list-style: none;
  border-radius: 5px;
  margin: 0px 4px 10px 4px;
}

/*Toolbar Styling*/

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 10px;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-text {
  padding-bottom: 2px;
}

.checkbox {
  padding-top: 0px;
  padding-left: 0px;
  margin-top: 0px;
  margin-left: 0px;
}

/*list title container styling*/
.list-title-container {
  display: flex;
  align-items: center;
  padding: 0px 10px 8px 10px;
}

.title-input-container {
  display: flex;
  flex: 4;
  padding: 0px 8px 0px 8px;
}

.title-input {
  width: 100%;
}

.time-input-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.time-in-mins-container {
  display: flex;
  flex-direction: row;
  padding: 0px 10px 0px 10px;
  justify-content: space-between;
}

.time-in-mins {
  margin: 0;
  padding: 0;
}

.alignment-title {
  padding: 0px 10px 0px 10px;
  font-weight: bold;
  margin-bottom: 0px;
}

/*edit the alignment buttons and inputs styling*/
.change-alignment-container {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 4px 0px;
  padding: 0px 10px 0px 10px;
}

.alignment-container {
  display: flex;
  align-items: center;
}

.alignment-button-container {
  margin: 0px 4px 0px 4px;
}

/*add button styling*/
.add-subtitle-button-container {
  display: flex;
  justify-content: space-around;
  height: 16px;
  width: 100%;
}

.list-item-action-button {
  transition: opacity 0.2s;
}

.character-warning-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 2px 8px;
  border-radius: 5px;
}

.character-warning-text {
  margin: 0;
  padding: 1px 2px 1px 2px;
  font-size: 14px;
}

@media only screen and (max-width: 1000px) {
  .list-item-container {
    font-size: 16px;
  }
  .change-alignment-container {
    flex-direction: column;
  }
  .list-title-container {
    flex-direction: column;
    align-items: start;
  }
  .title-input-container {
    width: 100%;
    padding: 10px 0px 10px 0px;
  }
}
