.sc-aXZVg {
    background-color: transparent;
    border: 2px dashed rgb(6, 88, 194);
}

.guZdik {
    max-width: 180px!important;
    min-width: 80px!important;
    height: 30px!important;
    margin: 0px!important;
    margin-right: 8px!important;
}

.upload-button {
    margin: 0px 4px 0px 0px;
}
